<template>
    <div class="fan-analysis">
        <div class="analysis-top">
            <div class="analysis-left">
                <div class="gender-distribution">
                    <span class="title">性别分布</span>
                    <div class="distribution-chart">
                        <div class="sex-name">
                            <span class="name-span">{{woman}}%</span>
                            <span class="name-span">{{man}}%</span>
                        </div>
                        <div class="chart-content">
                            <div class="mab" :style="{width: woman+'%'}">
                                <div class="mab-color"></div>
                            </div>
                            <div class="woman">
                                <div class="woman-color"></div>
                            </div>
                        </div>
                        <div class="gender-icon">
                            <div class="gender-icon-left">
                                <i class="iconfont">&#xe6a8;</i>
                                <span>女性用户</span>
                            </div>
                            <div class="gender-icon-right">
                                <i class="iconfont">&#xe6a6;</i>
                                <span>男性用户</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="analysis-right">
                <div class="age-distribution">
                    <span class="title">年龄分布</span>
                    <div id="ageDistribution" style="width: 100%;height: 275px;position: relative"></div>
                </div>
            </div>
        </div>
        <div class="analysis-bottom">
            <span class="title">地区分布</span>
            <div class="two-title">
                <span>名称</span>
                <span>占比</span>
            </div>
            <div class="area-distribution">
                <div class="area-item-box" v-if="areaData.length > 0">
                    <div class="area-item" v-for="item in areaData">
                        <span class="area-name">{{item.areaName}}</span>
                        <div class="area-progress-box">
                            <div class="area-progress-num" :style="{width:item.percentage + '%'}"></div>
                        </div>
                        <span class="percentage text-overflow">{{item.percent}}%</span>
                    </div>
                </div>
                <div v-else class="null-data-area">暂无数据</div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: ["videoId"],
        data() {
            return {
                id: "",
                radio: 0,
                tableData: [],
                genderData: {},
                areaData: [],
                man: 0,
                woman: 0,
            }
        },
        created() {
            this.getVideoDetail();
            if (this.$route.query.videoId != "") {
                this.id = this.$route.query.videoId;
            }
        },
        mounted() {
            this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
        },
        methods: {
            getListByCh() {
                this.areaData = [];
                if (this.radio == 0) {
                    this.areaDataFun(this.tableData.province_dist);
                } else {
                    this.areaDataFun(this.tableData.city_dist);
                }
            },
            getVideoDetail() {
                let param = {
                    id: this.videoId ? this.videoId : this.id
                };
                this.$httpStudent.axiosGetBy(this.$api.getFansAnalysis, param, res => {
                    if (res.code == 200) {
                        this.tableData = res.info.video_detail_fans_data;
                        this.areaDataFun(this.tableData.province_dist);
                        this.genderData = this.tableData.gender_dist;
                        this.ageDistributionFun(this.tableData.birthday_dist);
                        let sum = this.genderData['0'] + this.genderData['1'] + this.genderData['2'];
                        this.man = Math.round((this.genderData['0'] + this.genderData['2']) / sum * 10000) / 100.00;
                        this.woman = Math.round(this.genderData['1'] / sum * 10000) / 100.00;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            areaDataFun(arry) {
                //地区数据处理
                let newArray = Object.entries(arry);
                let sum = newArray[0][1];
                let sum1=0;
                for (let i in arry) {
                    sum1 += arry[i];
                }
                if (newArray.length > 10) {
                    for (let j=0;j<=10;j++) {
                        let obj = {
                            areaName: newArray[j][0],
                            percentage: Math.round(newArray[j][1] / sum * 10000) / 100.00,
                            percent:Math.round(newArray[j][1] / sum1 * 10000) / 100.00
                        };
                        this.areaData.push(obj);
                    }

                } else {
                    for (let i in arry) {
                        let obj = {
                            areaName: i,
                            percentage: Math.round(arry[i] / sum * 10000) / 100.00,
                            percent:Math.round(arry[i] / sum1 * 10000) / 100.00
                        };
                        this.areaData.push(obj);
                    }
                }
            },
            //计算百分比
            getPercent(curNum, totalNum, isHasPercentStr){
                curNum = parseFloat(curNum);
                totalNum = parseFloat(totalNum);
                if(isNaN(curNum) || isNaN(totalNum)){
                    return '-';
                }
                return isHasPercentStr ?
                    totalNum <= 0 ? '0%' : (Math.round(curNum / totalNum * 10000) / 100.00 + '%') :
                    totalNum <= 0 ? 0 : (Math.round(curNum / totalNum * 10000) / 100.00);
            },
            ageDistributionFun(array) {
                //年龄段数据处理
                let ageArray = [], dataArray = [];
                for (let i in array) {
                    ageArray.push(i);
                    let obj = {
                        value: array[i],
                        name: i
                    };
                    dataArray.push(obj)
                }
                ageArray.sort();
                this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
                this.ageDistribution.setOption({
                    tooltip : { //提示框组件
                        trigger: 'item', //触发类型(饼状图片就是用这个)
                        formatter:function (params){
                            return params.seriesName + '<br/>' + params.name + ':' + params.value+'%'
                        }
                    },
                    color:['#9ECB59','#5F55F1','#C3C3F4','#F5697B','#F5AF23'],
                    legend: {
                        padding:[0,60,0,0],
                        orient : 'horizontal',  //布局  纵向布局 图例标记居文字的左边 vertical则反之
                        width:40,      //图行例组件的宽度,默认自适应
                        x : 'right',   //图例显示在右边
                        y: 'bottom',   //图例在垂直方向上面显示居中
                        itemWidth:10,  //图例标记的图形宽度
                        itemHeight:10, //图例标记的图形高度
                        data:ageArray,
                        textStyle:{
                            color:'#000',
                            fontSize:14,
                            lineHeight:22
                        },
                        formatter: function (params) {
                            return params + '岁'
                        }
                    },
                    series: [{
                        name: '年龄分布',
                        type: 'pie',
                        data: dataArray,
                        center:['40%','58%'],
                        radius : ['60%', '70%'],
                        itemStyle:{
                            normal:{
                                label : {  //饼图图形上的文本标签
                                    show : true,  //平常不显示
                                    color:'#000',
                                    fontSize:18,
                                    formatter: function (params) {
                                        return params.value + '%';
                                    }
                                },
                                labelLine : {     //标签的视觉引导线样式
                                    show : true  //平常不显示
                                },
                            },
                            emphasis:{
                                label:{
                                    show : true,
                                    position : 'center',
                                    textStyle : {
                                        fontSize : '10',
                                        fontWeight : 'bold'
                                    }
                                }
                            }
                        }
                    }]
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .fan-analysis {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 20px;
        .analysis-top{
            display: flex;
            border-bottom: 5px solid #EEEEEE;
            padding-bottom: 34px;
            margin: 0 30px;
            .analysis-left {
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 100%;
                border-right: 5px solid #EEEEEE;
                padding: 0 54px 0 24px;
                .gender-distribution {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .title {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                    .distribution-chart {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        height: 1%;
                        justify-content: center;
                        .sex-name{
                            display: flex;
                            justify-content: space-between;
                            .name-span{
                                color: #000000;
                                font-size: 18px;
                                line-height: 1;
                            }
                        }
                        .chart-content{
                            width: 100%;
                            display: flex;
                            .mab{
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                .mab-color{
                                    background: #FF6A6A;
                                    border-radius: 7px;
                                    height: 12px;
                                    margin-top: 50px;
                                }
                            }
                            .woman{
                                width: 1%;
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                .woman-color{
                                    width: 100%;
                                    height: 12px;
                                    background: #564CED;
                                    border-radius: 7px;
                                    margin-top: 50px;
                                }
                            }
                        }
                        .gender-icon{
                            display: flex;
                            justify-content: space-between;
                            margin-top: 54px;
                            .gender-icon-left, .gender-icon-right{
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                &.gender-icon-left{
                                    i{
                                        color: #FF6A6A;
                                    }
                                }
                                &.gender-icon-right{
                                    i{
                                        color: #564CED;
                                    }
                                }
                                i{
                                    font-size: 40px;
                                }
                                span{
                                    font-size: 14px;
                                    color: #444444;
                                    line-height: 1;
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
            .analysis-right {
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 100%;
                .age-distribution {
                    padding-bottom: 20px;
                    .title {
                        padding-left: 32px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                }
            }
        }
        .analysis-bottom{
            display: flex;
            flex-direction: column;
            padding: 0 34px;
            .title{
                line-height: 1;
                margin-top: 58px;
            }
            .two-title{
                line-height: 1;
                display: flex;
                justify-content: space-between;
                padding: 0 14px 0 26px;
                color: #666666;
                font-size: 14px;
                margin-top: 50px;
            }
            .area-distribution{
                padding: 0 14px 0 26px;
                display: flex;
                flex-direction: column;
                .area-item-box{
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 20px;
                    .area-item{
                        display: flex;
                        align-items: center;
                        line-height: 1;
                        margin-top: 40px;
                        span{
                            color: #666666;
                            font-size: 14px;
                            &.area-name{
                                text-align: left;
                                width: 50px;
                            }
                            &.percentage{
                                width: 60px;
                                text-align: right;
                            }
                        }
                        .area-progress-box{
                            display: flex;
                            flex: 1;
                            width: 1%;
                            .area-progress-num{
                                height: 15px;
                                background: linear-gradient(45deg, #FFA72A 0%, #F8B95F 98%);
                                border-radius: 7px;
                            }
                        }
                    }
                }
            }
        }
        .null-data-area{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
        }
    }
</style>