<template>
    <div class="favorite">
        <div class="leftBox">
            <div class="DsVideoDetail-header">
                <img :src="videoList.video_cover ||''" alt="" class="headerCover">
                <div class="headerCountent">
                    <p class="headerCountentTitle">{{videoList.video_title}}</p>
                    <div class="headerCountentLike">
                        <i class="iconfont">&#xe62a;</i><span>{{videoList.video_total_likes}}</span>
                        <i style="margin-left: 17px" class="iconfont">&#xe6e7;</i><span>{{videoList.video_total_forwarding}}</span>
                        <i style="margin-left: 17px" class="iconfont">&#xe664;</i><span>{{videoList.video_total_comments}}</span>
                    </div>
                    <div class="headerCountenHot">
                        <span>热词:</span>
                        <span v-if="videoList.video_tags == '0'">暂无</span>
                        <span v-else class="hotWords" v-for="item in video_tags"
                              :key="item">{{item}}</span>
                    </div>
                    <p class="issueTime">发布时间:{{videoList.time}}</p>
                </div>
                <div class="headerBtn"><a target="_blank" style="color: #ffffff;cursor: pointer;" @click="jumpToVideo">播放视频</a>
                </div>
            </div>
            <div class="tab_box">
                <el-tabs v-model="ShortTab" type="card" @tab-click="handleClick">
                    <el-tab-pane label="视频详情" name="first">
                        <detailTab ref="detailTab" v-if="this.ShortTab == 'first'" :videoId=this.videoId>
                        </detailTab>
                    </el-tab-pane>
                    <el-tab-pane label="视频观众分析" name="second">
                        <audiences ref="audiences" v-if="this.ShortTab == 'second'" :videoId=this.videoId>
                        </audiences>
                    </el-tab-pane>
                    <el-tab-pane label="商品分析" name="third">
                        <productAnalysis ref="productAnalysis" v-if="this.ShortTab == 'third'" @videoId="handleBack"
                                         @activename="handleBack1"
                                         :videoId=this.videoId>
                        </productAnalysis>
                    </el-tab-pane>
                    <el-tab-pane label="电商视频热度监控" name="fourth">
                        <monitoring ref="monitoring" v-if="this.ShortTab == 'fourth'" :videoId=this.videoId>
                        </monitoring>
                    </el-tab-pane>
                </el-tabs>
                <div class="update-content">
                    <span @click="updateBtn" class="update-title"><i class="iconfont">&#xe64d;</i>更新数据</span>
                </div>
            </div>
        </div>
        <!--右侧帮助中心-->
        <RightHelpList></RightHelpList>
    </div>
</template>

<script>
    import audiences from '../../../components/mediumModule/audiences'
    import detailTab from '../../../components/mediumModule/detailTab'
    import productAnalysis from '../../../components/mediumModule/productAnalysis'
    import monitoring from '../../../components/mediumModule/monitoring'
    import RightHelpList from '@/components/helpCenter/RightHelpList.vue'

    export default {
        name: "VideoDetail",
        components: {
            audiences,
            detailTab,
            productAnalysis,
            monitoring,
            RightHelpList
        },
        data() {
            return {
                ShortTab: this.$route.query.activename || 'first',
                videoList: {},//视频详情
                videoId: "",
                video_tags: '',
            }
        },
        created() {
            this.videoId = this.$route.query.videoId;
            this.getVideoList();
        },
        methods: {
            //打开视频
            jumpToVideo() {
                window.open(this.videoList.video_url);
            },
            getVideoList() {
                this.$httpStudent.axiosGetBy(this.$api.getVideoDetailById, {id: this.videoId,}, res => {
                    if (res.code == 200) {
                        this.videoList = res.info[0];
                        this.video_tags = res.info[0].video_tags;
                        this.formatTags();
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            formatTags() {
                if (this.video_tags !== '0') {
                    let arr = this.video_tags.split(',');
                    let len = arr.length;
                    arr.splice(len - 1, 1)
                    this.video_tags = arr;
                }
            },
            updateBtn() {
                if (this.ShortTab === 'first') {
                    this.$nextTick(() => {
                        this.$refs.detailTab.getVideoDetail();
                    })
                }
                if (this.ShortTab === 'second') {
                    this.$nextTick(() => {
                        this.$refs.audiences.getVideoDetail();
                    })
                }
                if (this.ShortTab === 'third') {
                    this.$nextTick(() => {
                        this.$refs.productAnalysis.getVideoDetail();
                    })
                }
                if (this.ShortTab === 'fourth') {
                    this.$nextTick(() => {
                        this.$refs.monitoring.getVideoDetail();
                    })
                }
            },
            handleClick(tab, event) {
            },
            handleBack(val) {
                this.videoId = val;
                this.getVideoList();
            },
            handleBack1(val) {
                this.ShortTab = val;
            }
        }
    }
</script>

<style scoped lang="scss">
    .favorite {
        height: 100%;
        display: flex;

        .leftBox {
            width: 80%;
            display: flex;
            flex-direction: column;

            .DsVideoDetail-header {
                position: relative;
                display: flex;
                background: #ffffff;
                padding: 20px 24px;

                .headerCover {
                    width: 105px;
                    height: 130px;
                }

                .headerCountent {
                    overflow: hidden;
                    margin-left: 26px;

                    .headerCountentTitle {
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;
                        font-size: 16px;
                        width: 432px;
                        height: 44px;
                        margin: 0;
                    }

                    .headerCountentLike {
                        margin-top: 8px;
                        display: flex;
                        align-items: center;

                        i {
                            font-size: 20px;
                            color: #333333;
                            line-height: 21px;
                        }

                        span {
                            color: #333333;
                            font-size: 16px;
                            font-weight: 400;
                            margin-left: 6px;

                        }
                    }

                    .headerCountenHot {
                        overflow: hidden;

                        span {
                            margin-top: 12px;
                        }

                        span:nth-child(1) {
                            color: #999999;
                            font-size: 12px;
                        }

                        .hotWords {
                            display: inline-block;
                            color: #333333;
                            font-size: 12px;
                            padding: 6px 16px;
                            background: #F4F4F4;
                            border-radius: 12px;
                            line-height: 1;
                            font-weight: 400;
                            margin-left: 15px;
                        }
                    }

                    .issueTime {
                        color: #999999;
                        font-size: 12px;
                        line-height: 1;
                        margin-top: 10px;
                        margin-bottom: 0;
                    }
                }

                .headerBtn {
                    width: 106px;
                    height: 32px;
                    background: linear-gradient(85deg, #FF0000, #FF6060,);
                    border-radius: 2px;
                    color: #ffffff;
                    text-align: center;
                    line-height: 32px;
                    font-size: 14px;
                    position: absolute;
                    right: 2%;
                    top: 9%;
                }
            }

            .tab_box {
                display: flex;
                flex: 1;
                height: 1%;
                margin-top: 20px;
                position: relative;

                ::v-deep .el-tabs {
                    height: 100%;
                    width: 100%;
                    box-shadow: none;
                    border: none;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    background: transparent;
                }

                ::v-deep .el-tabs__header {
                    border-bottom: none;
                    margin: 0;
                }

                ::v-deep .el-tabs__content {
                    height: 1%;
                    flex: 1;
                    background: #fff;
                    padding-top: 20px;
                    min-height: 600px;
                }

                ::v-deep .el-tabs__header .is-active {
                    background: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    color: #0824ce;
                    border-radius: 6px 6px 0 0;
                }

                ::v-deep .el-tabs__item {
                    border: none;
                    height: 52px;
                    line-height: 52px;
                }

                ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                    border: none;
                }

                ::v-deep .el-tab-pane {
                    height: 100%;
                }

                .update-content {
                    height: 20px;
                    position: absolute;
                    top: 14px;
                    right: 0;

                    .update-title {
                        margin: 0 10px;
                        font-size: 14px;
                        cursor: pointer;

                        i {
                            color: #0824ce;
                            margin-right: 10px;
                        }

                        &:hover {
                            color: #1D33E1;
                        }
                    }
                }
            }
        }
    }

</style>