<template>
    <div class="contain_box">
        <div class="head_title">
            <div class="left_title">
                <div class="pic_box"></div>
                <div class="data_box">
                    <p>{{this.videoData.video_author}}</p>
                    <p>{{this.videoData.follower_count}}粉丝</p>
                </div>
            </div>
            <div class="right_title">
                <div class="box1">
                    <p>总点赞</p>
                    <p style="text-align: center">{{this.videoData.video_author_total_favorited}}</p>
                </div>
                <div class="divider"></div>
                <div class="box1">
                    <p>总评论</p>
                    <p style="text-align: center">{{this.videoData.video_author_comment_count}}</p>
                </div>
                <div class="divider"></div>
                <div class="box1">
                    <p>总转发</p>
                    <p style="text-align: center">{{this.videoData.video_author_share_count}}</p>
                </div>
            </div>
        </div>
        <div class="middle_box">
            <div style="margin-left: 40px;">热门评论({{this.tableData.length}})</div>
        </div>
        <div class="main_box">
            <el-table
                    :show-header="false"
                    :data="tableData"
                    style="width: 100%;">
                <el-table-column prop="text" label="评论">
                    <template slot-scope="scope">
                      <p class="student-name-title">{{scope.row.text}}</p>
                      <div class="author-blk">
                         <img class="author_avatar" :src=scope.row.author_avatar>
                         <p style="margin-right: 50px;color:rgba(153,153,153,1);font-size:14px;">{{scope.row.author_nickname}}</p>
                         <p style="font-size:14px;font-weight:400;color:rgba(153,153,153,1)"
                             class="student-name-title"> {{scope.row.create_time}}</p>
                      </div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" prop="digg_count" label="点赞" width="180">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <i class="iconfont">&#xe85c;</i>
                            <span style="margin-left: 5px"
                                  class="student-name-title">{{scope.row.digg_count}}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["videoId"],
        data() {
            return {
                videoData: {},
                tableData: [],
                id: "",
            }
        },
        created() {
            if (this.$route.query.videoId != "") {
                this.id = this.$route.query.videoId;
            }
            this.getVideoDetail();
        },
        methods: {
            getVideoDetail() {
                let param = {
                    id: this.videoId ? this.videoId : this.id,
                };
                this.$httpStudent.axiosGetBy(this.$api.getVideoDetail, param, res => {
                    if (res.code == 200) {
                        this.videoData = res.info;
                        if (res.info.video_detail_comment) {
                            this.tableData = res.info.video_detail_comment;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .contain_box {
        height: 100%;

        .head_title {
            height: 80px;
            display: flex;
            justify-content: space-between;

            .left_title {
                margin-left: 40px;
            }

            .right_title {
                margin-right: 40px;
                display: flex;

                .divider {
                    background-color: #DCDFE6;
                    display: inline-block;
                    width: 1px;
                    height: 3em;
                    margin-top: 18px;
                    margin-right: 8px;
                    margin-left: 8px;
                    vertical-align: middle;
                    position: relative;
                }
            }
        }

        .middle_box {
            padding-top: 40px;
        }

        .main_box {
            padding-top: 20px;
            margin-left: 40px;
        }
    }
    .author_avatar{
      width: 22px;
      height: 22px;
      border-radius:50%;
      margin-right: 10px;
    }
    .author-blk {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
</style>