<template>
    <div class="contain_box">
        <div class="head_title" v-if="this.videoData.goods_name">
            <div class="left_title">
                <div class="pic_box"></div>
                <div class="data_box"  style="display: flex">
                    <img :src=this.videoData.goods_image alt="" style="height: 80px;width: 80px">
                    <div style="height: 80px;margin-left: 50px">
                      <p style="margin-top: 0;" @click="jumpToGoods">{{this.videoData.goods_name}}</p>
                      <div >
                        <span style="">￥{{this.videoData.goods_price}}</span>
                        <span style="font-size:24px;font-weight:bold;color: #FF0000;">{{this.videoData.price}}</span>
                      </div>
                      <div style="">
                        <span>预计销量:{{this.videoData.goods_volume}}</span>
                        <span style="margin-left: 10px;">预计销售额：{{thhis.videoData.goods_amount}}</span>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      <div v-else></div>
      <div class="divider" v-if="this.videoData.goods_name">
        <el-divider></el-divider>
      </div>
        <div class="main_box">
            <div class="left-item-fan" v-show="digg_add.length>0">
                <h4 class="item-half-sub">点赞趋势</h4>
                <div class="left-item-content">
                    <div class="fan-tab">
                        <div class="item left" :class="{current: fanTabIndex === 0}" @click="toggleFanTab(0)">增量</div>
                        <div class="item right" :class="{current: fanTabIndex === 1}" @click="toggleFanTab(1)">总量</div>
                    </div>
                    <div id="fansGrowth" :style="{width: '1200px', height: '442px'}"></div>
                </div>
            </div>
            <div class="left-item-fan" v-show="share_add.length>0">
                <h4 class="item-half-sub">转发趋势</h4>
                <div class="left-item-content">
                    <div class="fan-tab">
                        <div class="item left" :class="{current: fanTabIndex1 === 0}" @click="toggleFanTab1(0)">增量</div>
                        <div class="item right" :class="{current: fanTabIndex1 === 1}" @click="toggleFanTab1(1)">总量</div>
                    </div>
                    <div id="fansGrowth1" :style="{width: '1200px', height: '442px'}"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["videoId"],
        data() {
            return {
                videoData: {},
                tableData: [],
                fanTabIndex: 0,
                fanTabIndex1: 0,
                digg_add: [],
                digg_total: [],
                share_add: [],
                share_total: [],
            }
        },
        mounted() {
            this.getVideoDetail();
        },

        methods: {
            getVideoDetail() {
                let param = {
                    id: this.videoId,
                };
                this.$httpStudent.axiosGetBy(this.$api.getHotVideoAnalysis, param, res => {
                    if (res.code == 200) {
                        if (res.info.video_detail_goods_data) {
                            this.videoData = res.info.video_detail_goods_data;
                        }
                        if (res.info.video_base_analysis.digg_add_data) {
                            this.digg_add = res.info.video_base_analysis.digg_add_data;
                            this.salsFun(this.digg_add);
                        }
                        if (res.info.video_base_analysis.digg_total_data) {
                            this.digg_total = res.info.video_base_analysis.digg_total_data;
                        }
                        if (res.info.video_base_analysis.share_add_data) {
                            this.share_add = res.info.video_base_analysis.share_add_data;
                            this.viewsFun(this.share_add)
                        }
                        if (res.info.video_base_analysis.share_total_data) {
                            this.share_total = res.info.video_base_analysis.share_total_data;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            jumpToGoods() {

            },

            toggleFanTab(index) {
                // console.log(index)
                this.fanTabIndex = index;
                if (this.fanTabIndex == 0) {
                    this.salsFun(this.digg_add);
                } else {
                    this.salsFun(this.digg_total);
                }
            },
            toggleFanTab1(index) {
                // console.log(index)
                this.fanTabIndex1 = index;
                if (this.fanTabIndex1 == 0) {
                    this.viewsFun(this.share_add);
                } else {
                    this.viewsFun(this.share_total);
                }
            },
            salsFun(fansTrendArry) {
                // console.log(fansTrendArry)
                // 时间和数据
                let time = [],
                    data = [];
                for (let i in fansTrendArry) {
                    // 保存时间和数据
                    time.push(fansTrendArry[i].time);
                    data.push(fansTrendArry[i].count);
                }
                this.fansGrowth = this.$echarts.init(document.getElementById('fansGrowth'));
                this.fansGrowth.setOption({
                    title: {
                        x: 'center',
                        y: 'bottom',
                        textStyle: { //设置主标题风格
                            Color: '#333333',//设置主标题字体颜色
                            fontSize: 14,
                            fontStyle: 'normal',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].name;
                            let date = params[0].data;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '点赞总数：' + '</span> <sapn class="tooltip-title-right purple"> ' + date + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        }
                    },
                    xAxis: {
                        data: time,
                        //设置网格线颜色
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                    },
                    yAxis: {
                        scale:true,
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#f1f1f1'],
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                        axisLabel: {
                            fontSize: '14',
                            marginTop: '35px',
                            formatter: '{value}',
                            show: true,
                        },
                    },
                    series: [{
                        data: data,
                        type: 'line',
                        lineStyle: {
                            color: '#857FDD',
                            width: 3,
                        },
                        smooth: true,
                        symbol: "none",
                    }]
                }, true);
            },
            viewsFun(fansTrendArry) {
                // console.log(fansTrendArry)
                // 时间和数据
                let time = [],
                    data = [];
                for (let i in fansTrendArry) {
                    // 保存时间和数据
                    time.push(fansTrendArry[i].time);
                    data.push(fansTrendArry[i].count);
                }
                this.fansGrowth1 = this.$echarts.init(document.getElementById('fansGrowth1'));
                this.fansGrowth1.setOption({
                    title: {
                        x: 'center',
                        y: 'bottom',
                        textStyle: { //设置主标题风格
                            Color: '#333333',//设置主标题字体颜色
                            fontSize: 14,
                            fontStyle: 'normal',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].name;
                            let date = params[0].data;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '转发总数：' + '</span> <sapn class="tooltip-title-right blue"> ' + date + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        }
                    },
                    xAxis: {
                        data: time,
                        //设置网格线颜色
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#f1f1f1'],
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                        axisLabel: {
                            fontSize: '14',
                            marginTop: '35px',
                            formatter: '{value}',
                            show: true,
                        },
                    },
                    series: [{
                        data: data,
                        type: 'line',
                        lineStyle: {
                            color: '#04A8FF',
                            width: 3,
                        },
                        smooth: true,
                        symbol: "none",
                    }]
                }, true);
            },
        }
    }
</script>

<style scoped lang="scss">
    .contain_box {
        height: 100%;

        .head_title {
            height: 80px;
            display: flex;
            justify-content: space-between;

            .left_title {
                margin-left: 40px;
            }
        }

        .divider {
            padding: 5px 20px 0 20px;
        }

        .main_box {
            padding-top: 20px;
            margin-left: 40px;

            .left-item-fan {
                margin-bottom: 20px;

                .item-half-sub {
                    font-size: 16px;
                    line-height: 24px;
                }

                .left-item-content {
                    background: #fff;
                    margin-top: 15px;
                    padding: 15px;

                    .fan-tab {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        text-align: center;
                        padding-right: 30px;
                        color: #999;
                        line-height: 1;
                        color: #666666;

                        .item {
                            cursor: pointer;
                            width: 50px;
                            height: 30px;
                            background: #F1F0FE;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.left {
                                border-radius: 5px 0px 0px 5px;
                            }

                            &.right {
                                border-radius: 0px 5px 5px 0px;
                            }
                        }

                        .current {
                            color: #FFFFFF;
                            background: #564CED;
                        }
                    }
                }

                ::v-deep.tooltip {
                    height: 59px;
                    border-radius: 2px;
                    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
                    background: #fff;

                    .tooltip-box {
                        padding: 13px 20px 10px;
                    }

                    .tooltip-title {
                        font-size: 12px;
                        color: #999999;
                    }

                    .tooltip-title-right {
                        float: right;
                        padding-right: 10px;
                        font-size: 16px;

                        &.purple {
                            color: #5F55F1;
                        }

                        &.blue {
                            color: #04A8FF;
                        }
                    }

                    .tooltip-title-left {
                        color: #333;
                    }
                }
            }
        }

        .current {
            color: #20C997;
        }
    }
</style>