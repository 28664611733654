<template>
    <div class="contain_box">
        <div class="goods_info">
            <div class="center">
                <p class="cancel_margin bold_font">{{this.videoData.goods_count}}</p>
                <p class="cancel_margin" style="color:rgba(153,153,153,1);">商品数</p>
            </div>
            <div class="center">
                <p class="cancel_margin bold_font">{{this.videoData.volume}}</p>
                <p class="cancel_margin" style="color:rgba(153,153,153,1);">总预估销量</p>
            </div>
            <div class="center">
                <p class="cancel_margin bold_font">{{this.videoData.amount}}</p>
                <p class="cancel_margin" style="color:rgba(153,153,153,1);">总预估销售额</p>
            </div>
        </div>
        <div class="divider">
            <!--            <el-divider></el-divider>-->
        </div>
        <div class="middle_box">
            <div class="data-trend">
                <div class="data-menu-box">
                    <span class="title">销量趋势</span>
                    <div class="data-menu">
                        <div class="item left" :class="{selected: fanTabIndex === 0}" @click="toggleFanTab(0)">7天</div>
                        <div class="item right" :class="{selected: fanTabIndex === 1}" @click="toggleFanTab(1)">30天</div>
                    </div>
                </div>
                <div class="content">
                    <div id="goodsTrend" :style="{width: '100%', height: '442px'}"></div>
                </div>
            </div>
        </div>
        <!--        <div class="divider">-->
        <!--            <el-divider></el-divider>-->
        <!--        </div>-->
        <div class="main_box">
            <el-table :data="tableData" stripe style="width: 100%"
                      :header-cell-style="{background:'#e7ecf1',color:'#555'}" v-if=tableData[0].goodsName>
                <el-table-column prop="goodsName" label="商品" width="300">
                    <template slot-scope="scope">
                        <div style="display: flex">
                            <img class="videoImg" :src="scope.row.goodsImage" alt="">
                            <div style="height: 35px;width: 200px;"><a :href=scope.row.goodsUrl class="goodsName"
                                                                       target="_blank">{{scope.row.goodsName}}</a></div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsPrice" label="价格" width="180">
                    <template slot-scope="scope">
                        <div>
                            <p style="font-weight: bold;">¥{{scope.row.goodsPrice}}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsRate" label="佣金比例">
                    <template slot-scope="scope">
                        <div>
                            <p style="font-weight: bold;margin: 0;line-height: 16px">{{scope.row.goodsRate}}%</p>
                            <p style="color:rgba(153,153,153,1);margin: 0;font-size: 10px;line-height: 12px">
                                约{{scope.row.goodsCommission}}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsVolume" label="预估销售">
                    <template slot-scope="scope">
                        <p style="font-weight: bold;">{{scope.row.goodsVolume}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsAmount" label="预估销售额">
                    <template slot-scope="scope">
                        <p style="font-weight: bold;">{{scope.row.goodsAmount}}</p>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>


</template>

<script>
    export default {
        props: ["videoId"],
        data() {
            return {
                videoData: {},
                // tableData: [],
                goodsInfo: {
                    x: [],
                    y: [],
                },
                tableData: [
                    {
                        goodsName: '',//名称
                        goodsImage: '',//图片
                        goodsPrice: '',//价格
                        goodsUrl: '',//链接
                        goodsRate: '',//佣金比例
                        goodsCommission: '',//佣金
                        goodsVolume: '',//预计销量
                        goodsAmount: '',//预计销售额
                    }
                ],
                //近7天/30天切换
                fanTabIndex: 0,
            }
        },
        created() {
            this.getVideoDetail();
        },
        mounted() {
            window.onresize = () => {
                // this.goodsData();
            };
        },
        methods: {
            getVideoDetail() {
                let param = {
                    id: this.videoId,
                };
                this.$httpStudent.axiosGetBy(this.$api.getVideoGoodsAnalysis, param, res => {
                    if (res.code == 200) {
                        if (res.info.video_detail_goods_data) {
                            this.videoData = res.info.video_detail_goods_data;
                            this.tableData[0].goodsName = res.info.video_detail_goods_data.goods_name;
                            this.tableData[0].goodsImage = res.info.video_detail_goods_data.goods_image;
                            this.tableData[0].goodsPrice = res.info.video_detail_goods_data.goods_price;
                            this.tableData[0].goodsRate = res.info.video_detail_goods_data.goods_commission_rate;
                            this.tableData[0].goodsCommission = res.info.video_detail_goods_data.goods_estimated_commission;
                            this.tableData[0].goodsVolume = res.info.video_detail_goods_data.goods_volume;
                            this.tableData[0].goodsAmount = res.info.video_detail_goods_data.goods_amount;
                            this.tableData[0].goodsUrl = res.info.video_detail_goods_data.goods_url;

                            //曲线图数据
                            this.goodsData();
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            jumpToGoods() {
                window.open(this.videoData.share_url);
            },
            jumpToVideoDetail(row) {
                this.$emit("videoId", row.id);
                this.$emit("activename", "first");
            },
            jumpToAuthor(row) {
                console.log(row, 888);
            },
            toggleFanTab(index){
                this.fanTabIndex = index;
                this.goodsData();
            },
            goodsData() {
                let arr = [];
                this.goodsInfo.x = []
                this.goodsInfo.y = []
                if(this.fanTabIndex === 0){
                    arr = this.videoData.data.slice(-7)
                } else {
                    arr = this.videoData.data.slice(-30)
                }
                arr.forEach((value, index) => {
                    this.goodsInfo.x.push(value.time);
                    this.goodsInfo.y.push(value.count);
                })
                this.goodsTrend = this.$echarts.init(document.getElementById("goodsTrend"));
                let x = this.goodsInfo.x;
                let y = this.goodsInfo.y;
                this.option = {
                    title: {
                        x: "center",
                        y: "bottom",
                        textStyle: {
                            //设置主标题风格
                            Color: "#333", //设置主标题字体颜色
                            fontSize: 14,
                            fontStyle: "normal"
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].name;
                            let date = params[0].data;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '销量总数：' + '</span> <sapn class="tooltip-title-right blue"> ' + date + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        }
                    },
                    xAxis: {
                        type: "category",
                        data: this.goodsInfo.x,
                        //设置网格线颜色
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#999"
                            }
                        }
                    },
                    yAxis: {
                        scale:true,
                        type: "value",
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#f1f1f1"]
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#999"
                            }
                        },
                        axisLabel: {
                            fontSize: "14",
                            marginTop: "35px",
                            formatter: "{value}",
                            show: true
                        }
                    },
                    series: [
                        {
                            data: this.goodsInfo.y,
                            type: "line",
                            lineStyle: {
                                color: "#857FDD",
                                width: 3
                            },
                            smooth: true,
                            symbol: "none"
                        }
                    ]
                };
                this.goodsTrend.setOption(this.option);
            }
        }
    }
</script>

<style scoped lang="scss">
    .contain_box {
        height: 100%;

        .head_title {
            height: 80px;
            display: flex;
            justify-content: space-between;

            .left_title {
                margin-left: 40px;
            }
        }

        .divider {
            padding: 5px 20px 0 20px;
        }

        .main_box {
            padding-top: 20px;
            margin-left: 40px;
        }

        .goods_info {
            height: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #584EEE;
        }

        .cancel_margin {
            padding: 0px;
            margin: 0;
        }

        .bold_font {
            font-weight: bold;
            font-size: 25px;
        }

        .center {
            margin: auto;
            text-align: center;
        }

        .data-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            text-align: center;

            .item {
                cursor: pointer;
                width: 50px;
                height: 30px;
                background: #F1F0FE;
                display: flex;
                justify-content: center;
                align-items: center;

                &.left {
                    border-radius: 5px 0px 0px 5px;
                }

                &.right {
                    border-radius: 0px 5px 5px 0px;
                }
            }

            .selected {
                color: #FFFFFF;
                background: #564CED;
            }
        }
    }

    .data-trend {
        margin-top: 20px;

        .data-menu-box {
            display: flex;
            justify-content: space-between;
            padding: 0 60px;
        }

        ::v-deep.tooltip {
            height: 59px;
            border-radius: 2px;
            box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
            background: #fff;

            .tooltip-box {
                padding: 13px 20px 10px;
            }

            .tooltip-title {
                font-size: 12px;
                color: #999999;
            }

            .tooltip-title-right {
                float: right;
                padding-right: 10px;
                font-size: 16px;

                &.blue {
                    color: #564CED;
                }
            }

            .tooltip-title-left {
                color: #333;
            }
        }
    }

    .videoImg {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .goodsName {
        margin-left: 10px;
        color: #333333;
        font-size: 14px;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>